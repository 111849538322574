.row-border-bottom {
  border-bottom: 1px solid #DADADA;
}
.row-border-top {
  border-top:  1px solid #DADADA;
}
.expanded-margin {
  margin-left: 57px;
}
.min-width-button-table {
  white-space: nowrap;
}

.no-border.mat-row {
  border-bottom-style: hidden !important;

  &:hover {
    background: transparent !important;
  }
}
.no-border.mat-table {
  .mat-row {
    border-bottom-style: hidden !important;
  }
}

.items-table {
  width: 100%;
  border-collapse: collapse;

  .mat-header-row {
    height: 40px;
    background: map-get($Yeti-primary, 100);
  }
  th {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px !important;
    line-height: 14px;
    color: map-get($mat-grey, A100) !important;
  }
  .mat-cell,
  .mat-header-cell {
    text-align: left;
    background: inherit;
  }
 
  .mat-header-cell {
    padding: 0 10px;
    color: map-get($mat-grey, A100) !important;
    font-weight: normal !important;
  }
  .mat-cell {
    padding: 10px;
    border-bottom: none;
    color: map-get($mat-grey, A100);
    font-size: 14px;
    line-height: 18px;
    &.misc-row-cell {
      padding-top: 26px;
      padding-bottom: 26px;
    }
  }
  .label {
    width: 85px;
    min-width: 85px;
  }
}

.large-screen-table.mat-table {
  .mat-header-row {
    height: 40px;
    background: transparent;
    border-bottom: 2px solid mat-color($mat-grey, 600) !important;
  }

  .mat-header-cell {
    border-top: none !important;
    padding-left: 15px !important;
    border-top: transparent;
    color: map-get($mat-grey, A100) !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .mat-cell {
    color: map-get($mat-grey, A100) !important;
    padding-left: 15px !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.small-screen-table {
  .mat-header-row {
    height: 40px;
  }
  th {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px !important;
    line-height: 14px;
    color: map-get($mat-grey, A100) !important;
  }

  th,
  td {
    color: map-get($mat-grey, A100);

    &:first-child {
      padding-left: 48px !important;
    }

    &:last-child {
      padding-right: 48px !important;
    }
  }
}

.mat-table {
  width: 100%;
  border-collapse: collapse;

  .mat-header-row {
    min-height: 44px;
    background: map-get($Yeti-primary, 100);
    &.two-levels-header {
      min-height: 54px;
    }
    &.three-levels-header {
      min-height: 75px;
    }
    .mat-header-cell {
      color: $black;
      font-size: 14px;
      border-top: 1px solid map-get($Yeti-primary, 600);
    }
  }

  .mat-footer-row,
  .mat-row {
    align-items: stretch;
    min-height: 50px;
    background: inherit;
  }

  .mat-cell:first-of-type,
  .mat-footer-cell:first-of-type,
  .mat-header-cell:first-of-type {
    padding-left: 24px;
  }
  .mat-cell,
  .mat-header-cell {
    text-align: left;
    background: inherit;

    mat-checkbox {
      label {
        margin-bottom: 0;
      }
    }
  }

  .mat-header-cell {
    padding: 0 10px;
  }

  .mat-cell {
    padding: 10px;
  }

  &.small-table-row {
    .mat-header-row,
    .mat-footer-row,
    .mat-row {
      height: 32px;
    }
  }
}

app-collection-results,
.app-collection-results  {
  mat-header-cell {
    .left-icon-spacing {
        padding-left: 38px;
    }
    &.text-left {
      .mat-sort-header-content {
        text-align: left;
      }
    }
  }
  
  mat-cell {
    .left-arrow-spacing {
        padding-left: 20px;
    }
    .info-block {
      display: flex;
      align-items: center;
    
      .icon-info {
        margin-right: 20px;
        font-size: 18px;
    
        &:hover {
          color: mat-color($mat-grey, A100) !important;
        }
      }

      .left {
        align-self: center;
      }
      .right {
        align-self: center;
        padding-left: 10px;
      }
    }
  }

  .mat-column-_actions {
    padding: 0 !important;
    max-width: 1px;
    overflow: visible;
    .mat-cell-contents {
      height: 100%;
  }
}
}
.order-info-tooltip,
.address-tooltip,
.sale-reps-tooltip {
  p {
    margin: 0px;
    font-size: 14px;
    line-height: 19px;
  }

  mat-divider {
    margin: 5px 0px;
  }
}
.mat-paginator-outer-container {
  display: flex;
}

.mat-paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 56px;
  padding: 0 8px;
  flex-wrap: wrap-reverse;
  width: 100%;
  font-size: 14px;
}

.mat-paginator-page-size {
  display: flex;
  align-items: baseline;
  margin-right: 8px;
}

.mat-paginator-page-size-label {
  margin: 0 4px;
}

.mat-paginator-range-actions {
  display: flex;
  align-items: center;
}

.mat-paginator-range-label {
  margin: 0 32px 0 24px;
}

.mat-cell-contents {
  width: 100%;
}

.stickyHeader {
  mat-header-row {
      position: sticky;
      top: 0;
      z-index: 101;
      transition: all 0.5s linear;
  }
}

.stickyFooter {
  position: sticky;
  bottom: 0;
  z-index: 101;
  .bulk-actions-wrapper,
  .mat-paginator {
      border-top: 1px solid mat-color($Yeti-primary, 500);
  }
}

.stickyFooterTable,
.stickyFooterPage {
        background: transparent;
        min-height: calc(100vh - 140px);
}

 .mat-paginator-page-size {
   .mat-select-trigger {
    font-size: 14px !important;
   }
  .mat-paginator-page-size-select {
      margin-top: 0;
  }
  .mat-form-field-appearance-legacy {
      .mat-form-field-wrapper {
          padding-bottom: 4px;
      }
      .mat-form-field-underline {
         display: none;
      }
  }
  mat-form-field .mat-input-element, mat-form-field .mat-select {
      padding: 5px 6px;
  }
  .mat-form-field-infix {
     border-top: 0;
   
  }
}
 .mat-paginator-range-actions {
  .mat-icon-button {
      height: 33px;
      line-height: 33px;
  }
}

 .mat-paginator-container {
  min-height: 50px;
}


.mat-cell-contents {
  width: 100%;
}

.row-actions-container {
  position: relative;
  height: 100%;

  .mat-icon-button {
    color: map-get($mat-grey, A100);
  }

  .row-actions {
    height: 100%;
    align-items: center;
    padding: 10px 15px 10px 70px;
    position: absolute;
    top: 0;
    right: 0;
    white-space: nowrap;
    display: none;
    background: -moz-linear-gradient(
        left,
        rgba(30, 87, 153, 0) 0%,
        rgba(36, 92, 156, 0) 1%,
        rgba(255, 255, 255, 0.94) 25%,
        rgba(255, 255, 255, 1) 27%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        rgba(30, 87, 153, 0) 0%,
        rgba(36, 92, 156, 0) 1%,
        rgba(255, 255, 255, 0.94) 25%,
        rgba(255, 255, 255, 1) 27%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        rgba(30, 87, 153, 0) 0%,
        rgba(36, 92, 156, 0) 1%,
        rgba(255, 255, 255, 0.94) 25%,
        rgba(255, 255, 255, 1) 27%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001e5799', endColorstr='#ffffff', GradientType=1);

    i {
      font-size: 20px;
      color: map-get($Yeti-primary, A100);
    }
  }
}

.mat-row {
  &:hover {
    background: map-get($Yeti-primary, 100);

    .row-actions {
      display: flex;
    }
  }
}
