/* You can add global styles to this file, and also import other style files */

@import "assets/style/icomoon";
@import "~bootstrap/scss/_functions";
@import "assets/style/bootstrap/_variables";
@import "~bootstrap/scss/_mixins";
@import "~bootstrap/scss/_utilities";
@import "~bootstrap/scss/_reboot";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_forms";
@import "~bootstrap/scss/_images";
@import "~bootstrap/scss/_badge";
@import "assets/style/bootstrap/_utilities";
@import "src/assets/style/material/variables";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 13px;
}

button {
  &:focus {
    outline: none;
  }
}

a {
  color: mat-color($mat-pink, 300);
}

.ghost-placeholder {
  display: block;
  width: 100%;

  .line-placeholder,
  .circle-placeholder {
    animation-duration: 1.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
  }

  .line-placeholder {
    height: 15px;
    display: block;

    &.large {
      height: 24px;
    }
  }

  .circle-placeholder {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    vertical-align: middle;

    &.large {
      height: 24px;
    }
  }

  &.inline {
    display: inline-block;

    .line-placeholder,
    .circle-placeholder {
      display: inline-block;
    }
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
}

.check-more-items-link {
  color: #aaa;
  font-weight: 400;
  font-size: 10px;
  margin-top: 5px;
  cursor: pointer;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.fixed-action {
  text-align: right;

  .fixed-primary-button {
    position: fixed !important;
    margin-left: -25px;
    bottom: 50px;
    font-size: 1.7em !important;
    color: #fff;
    background: mat-color($Yeti-accent, 500);
    outline: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.inline-display {
  display: inline-flex;
}
.dialog-full-height {
  width: 640px;

  @media (min-width: 1200px) {
    width: 832px;
  }

  mat-card-content {
    max-height: 60vh;
    overflow-y: auto;
  }
}

.item-padding-icon {
  padding-left: 40px;
}

.item-padding-no-icon {
  padding-left: 24px;
}

.overview-block {
  padding: 10px 0;

  .item {
    .label,
    .value {
      padding: 7px 0;
    }

    .label {
      color: mat-color($mat-grey, 700);

      i {
        font-size: 24px;
        margin-left: -40px;
        margin-right: 11px;
        color: mat-color($mat-grey, 700);
      }

      &.first {
        padding-top: 10px;
      }
    }
  }

  .left {
    margin-right: 20px;
  }

  .right {
    margin-right: -40px;
    margin-left: 20px;
  }

  .divider {
    border-bottom: 1px solid mat-color($mat-grey, 600);
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom:15px;
}

.default-icon-button {
  font-size: 18px;

  i {
    vertical-align: initial;
    margin-left: 0px !important;
    font-size: inherit;
  }
}

.outlined-default-button {
  font-size: 12px;
  font-weight: 400;
  border: 1px solid mat-color($mat-grey, 700);
  border-radius: 5px;
  padding: 2px 10px;
  background: $white;

  &:hover {
    background: mat-color($mat-pink, 50);
    color: mat-color($mat-pink, A200);
    border: 1px solid mat-color($mat-pink, A200);
  }
}

.table-padding-left {
  padding-left: 40px;
}

.table-padding-right {
  padding-right: 40px;
}
