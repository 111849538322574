.mat-tab-nav-bar {
    // custom tabs (gray nav bar)
    border-bottom: none;
    position: relative;
    &.stickyNav {
        position: sticky;
        top: 0;
        z-index: 201;
        transition: all 0.3s linear;
    }
	&.tabs-navigation {
	    border: none;
        .mat-tab-links {
            .container {
                border-bottom: 3px solid mat-color($Yeti-primary, 400);
            }
            .mat-tab-link {
                min-width: auto !important;
                opacity: 1;
                background: none !important;
                font-size: 16px;
                line-height: 40px;
                font-weight: 400;
                color: mat-color($Yeti-primary, A100) !important;
                &.mat-tab-label-active {
                    color:  mat-color($Yeti-accent, 950) !important;
                    font-weight: 500;
                }

                &:hover  {
                    color:  mat-color($Yeti-accent, 950) !important;
                }

            }
        }

		mat-ink-bar {
            height: 3px;
			background-color: mat-color($Yeti-accent, 950) !important;
		}
    }

    &.tabs {
        .mat-tab-links {
            border-bottom: 3px solid mat-color($Yeti-primary, 400);
        }
        .mat-tab-link {
            min-width: 165px;
            height: auto;
            padding: 17px 15px 17px 15px;
            color: mat-color($Yeti-primary, A100);
            font-weight: normal;
            text-decoration: none !important;
            opacity: 1;
            position: relative;
  
            &:after {
                content: " ";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                display: none;
                z-index: 10;
            }
            // &:hover {
            //     &:after {
            //         display: block;
            //     }
            // }

            &.mat-tab-label-active {
                background: white !important;
                &:after {
                    background: white !important;
                    display: block !important;
                }
            }

            &.mat-tab-disabled {
                opacity: 0.4;
                cursor: not-allowed;
            }

            i {
                margin-right: 10px;
                font-size: 18px;
                color: mat-color($Yeti-primary, 700);
            }
            mat-icon {
                height: 20px;
                width: 20px;
                font-size: 20px;
                margin-right: 10px;
                color: mat-color($Yeti-primary, 700);
            }

            .mat-chip-list {
                margin-left: 10px;
                pointer-events: none;

                .mat-chip {
                    min-height: 22px;
                    padding: 3px 7px;
                    font-weight: normal;
                    background: mat-color($Yeti-primary, 300);
                }
            }

            @each $name, $color in $Yeti-colors {
                &.hover-#{$name} {
                  &:after {
                      background: #{$color};
                  }
                }
              }
        }

        mat-ink-bar {
            top: auto;
            bottom: 0;
            height: 3px;
            background: mat-color($Yeti-primary, 800) !important;
        }

        @each $name, $color in $Yeti-colors {
            &.mat-#{$name} {
                mat-ink-bar {
                    background: #{$color} !important;
                }
            }
        }
       
    }
}

