@font-face {
  font-family: 'icomoon';
  src:  url('~assets/fonts/icomoon.eot?pbri46');
  src:  url('~assets/fonts/icomoon.eot?pbri46#iefix') format('embedded-opentype'),
    url('~assets/fonts/icomoon.ttf?pbri46') format('truetype'),
    url('~assets/fonts/icomoon.woff?pbri46') format('woff'),
    url('~assets/fonts/icomoon.svg?pbri46#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-password:before {
  content: "\e954";
}
.icon-fiber_manual:before {
  content: "\e953";
}
.icon-person_search:before {
  content: "\e952";
}
.icon-group:before {
  content: "\e94f";
}
.icon-how_to_reg:before {
  content: "\e950";
}
.icon-person_off:before {
  content: "\e951";
}
.icon-cancel:before {
  content: "\e947";
}
.icon-check-circle:before {
  content: "\e948";
}
.icon-check-pending-circle:before {
  content: "\e949";
}
.icon-circle-filled-half:before {
  content: "\e94a";
}
.icon-clock:before {
  content: "\e94b";
}
.icon-pending-circle:before {
  content: "\e94c";
}
.icon-quote:before {
  content: "\e94d";
}
.icon-report:before {
  content: "\e94e";
}
.icon-all-documents:before {
  content: "\e946";
}
.icon-document:before {
  content: "\e945";
}
.icon-activate:before {
  content: "\e944";
}
.icon-location-verified:before {
  content: "\e943";
}
.icon-location-rounded:before {
  content: "\e93d";
}
.icon-star:before {
  content: "\e93e";
}
.icon-key:before {
  content: "\e93f";
}
.icon-person-outlined:before {
  content: "\e940";
}
.icon-contacts-locations:before {
  content: "\e941";
}
.icon-contacts-list:before {
  content: "\e942";
}
.icon-arrow-sort-up:before {
  content: "\e93c";
}
.icon-materials:before {
  content: "\e938";
}
.icon-size:before {
  content: "\e936";
}
.icon-barcode:before {
  content: "\e935";
}
.icon-archive:before {
  content: "\e901";
}
.icon-phonelink:before {
  content: "\e93a";
}
.icon-pending2:before {
  content: "\e93b";
}
.icon-account:before {
  content: "\e932";
}
.icon-pending1:before {
  content: "\e937";
}
.icon-warning1:before {
  content: "\e939";
}
.icon-lock:before {
  content: "\e90c";
}
.icon-email:before {
  content: "\e906";
}
.icon-insert-drive-file:before {
  content: "\e915";
}
.icon-remove-red-eye:before {
  content: "\e916";
}
.icon-folder-shared:before {
  content: "\e92b";
}
.icon-info:before {
  content: "\e92c";
}
.icon-chevron-left:before {
  content: "\e92d";
}
.icon-chevron-up:before {
  content: "\e92e";
}
.icon-person:before {
  content: "\e92f";
}
.icon-send:before {
  content: "\e930";
}
.icon-print:before {
  content: "\e931";
}
.icon-save:before {
  content: "\e933";
}
.icon-approved:before {
  content: "\e934";
}
.icon-accounting:before {
  content: "\e902";
}
.icon-add:before {
  content: "\e903";
}
.icon-address:before {
  content: "\e904";
}
.icon-arrow-back:before {
  content: "\e905";
}
.icon-arrow-forward:before {
  content: "\e907";
}
.icon-chevron-down:before {
  content: "\e908";
}
.icon-arrow-sort:before {
  content: "\e909";
}
.icon-chevron-right:before {
  content: "\e90a";
}
.icon-banking:before {
  content: "\e90b";
}
.icon-circle-close:before {
  content: "\e90d";
}
.icon-close:before {
  content: "\e90e";
}
.icon-customers:before {
  content: "\e90f";
}
.icon-delete:before {
  content: "\e910";
}
.icon-dollar:before {
  content: "\e911";
}
.icon-edit:before {
  content: "\e900";
}
.icon-history:before {
  content: "\e912";
}
.icon-in-collection:before {
  content: "\e913";
}
.icon-inactive:before {
  content: "\e914";
}
.icon-inventory:before {
  content: "\e917";
}
.icon-leads:before {
  content: "\e918";
}
.icon-mouse:before {
  content: "\e919";
}
.icon-notes:before {
  content: "\e91a";
}
.icon-on-hold:before {
  content: "\e91b";
}
.icon-out-of-business:before {
  content: "\e91c";
}
.icon-payables:before {
  content: "\e91d";
}
.icon-pending:before {
  content: "\e91e";
}
.icon-phone:before {
  content: "\e91f";
}
.icon-pin:before {
  content: "\e920";
}
.icon-purchasing:before {
  content: "\e921";
}
.icon-receivables:before {
  content: "\e922";
}
.icon-sales:before {
  content: "\e923";
}
.icon-search:before {
  content: "\e924";
}
.icon-shipping:before {
  content: "\e925";
}
.icon-tasks:before {
  content: "\e926";
}
.icon-user:before {
  content: "\e927";
}
.icon-vendors:before {
  content: "\e928";
}
.icon-warehouse:before {
  content: "\e929";
}
.icon-warning:before {
  content: "\e92a";
}
