mat-dialog-container {
  box-shadow: none !important;
  
  .mat-dialog-title {
    padding: 13px 15px 14px 30px;
    margin: 0;
    min-height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close {
      position: relative !important;
      top: auto;
      right: auto;
      color: mat-color($mat-grey, A100);

    }
  }
  .dialog-message-wrapper {
    padding: 8px 100px;
   }

  .mat-dialog-content {
    padding: 22px 100px;
    margin: 0;
  }
  .dialog-content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 350px;

    .mat-dialog-content {
        min-height: 150px;
    }
}

  @media (max-width: 1440px) {
      .mat-dialog-content {
        padding: 22px  70px;
      }
      .dialog-message-wrapper {
        padding: 8px 70px;
       }
  }
  @media (max-width: 1024px) {
      .mat-dialog-content {
        padding: 22px  40px;
    }
    .dialog-message-wrapper {
      padding: 8px 40px;
     }
  }
 
  @media (max-width: 960px) {
  
    .mat-dialog-content {
      padding: 22px  20px;
    }
    .dialog-message-wrapper {
      padding: 8px 20px;
     }
  }
  
  .mat-dialog-actions {
    display: flex !important;
    padding: 0 !important;
    min-height: auto;
    margin: 0 !important;
    
    button {
      margin: 0 !important;
      border-radius: 0 !important;
      border-radius: 0;
    }
  }
}

.full-width-content {
  mat-dialog-container {
    .mat-dialog-content {
      padding: 0 !important;
    }
  }
}

.cdk-overlay-pane {

  &.dialog-size-small {
    width: 35vw;
    //height: 60vh;
    @media (max-width: 960px) {
      width: 50vw;
      //height: 70vh;
    }
    .mat-dialog-content {
      //flex: 1 1 0 !important;
      height: 100% !important;
      max-height: 100%;
    }
  }

  &.dialog-size-medium {
    width: 50vw;
    height: 70vh;
    @media (max-width: 960px) {
      width: 80vw;
      height: 80vh;
    }
    @media(orientation: portrait) {
      height:50vh;
    }
    .mat-dialog-content {
      flex: 1 1 0 !important;
      height: 100% !important;
      max-height: 100%;
    }
  }

  &.dialog-size-large {
    width: 80vw;
    height: 80vh;
    @media (max-width: 960px) {
      width: 95vw;
      height: 95vh;
    }
    @media(orientation: portrait) {
      height:70vh;
    }
    .mat-dialog-content {
      flex: 1 1 0 !important;
      height: 100% !important;
      max-height: 100%;
    }
  }

  &.dialog-size-full {
    width: 100vw;
    height: 100vh;
    max-width: 100vw !important;
    max-height: 100vh !important;
  }
}